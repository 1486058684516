import clsx from 'clsx'
import FooterBannerStyles from './FooterBanner.module.scss'

export const FooterBanner = ({ hasSidebar = false }) => {
  return (
    <div className={clsx(FooterBannerStyles['container'], hasSidebar ? FooterBannerStyles['sidebar'] : null)}>
      <div>QUT acknowledges the Traditional Owners of the lands where QUT now stands</div>
      <div>TEQSA Provider ID:PRV12079 (Australian University) | CRICOS No. 00213 | ABN: 83 791 724 622</div>
    </div>
  )
}
