import { Suspense, lazy, useState } from 'react'
import { KeycloakAdminService } from '@nv/auth'
import { Loader } from '@nv/basic-components'
import { BackgroundBanner, BottomBar, FooterBanner, LoginTopBar, SideBar, TopBar } from '@nv/components'
import { AdminUserProvider, LoginFlowContext, SessionTimerProvider, VoterOnboardingContext, VoterUserProvider } from '@nv/contexts'
import { useAdminKeycloakAuth, useVoterKeyCloakAuth } from '@nv/hooks'
import QueenslandLogoCollapsed from '@nv/images/logos/queensland/queensland-logo-collapsed'
import QueenslandLogoNavy from '@nv/images/logos/queensland/queensland-logo-navy'
import QueenslandLogoWhite from '@nv/images/logos/queensland/queensland-white-logo'
import { EMSIcon } from '@nv/images/nav-icons/ems'
import { Nominations } from '@nv/images/nav-icons/nominations'
import { Users } from '@nv/images/nav-icons/users'
import { beforeUnload } from '@nv/utils'
import { useTranslation } from 'react-i18next'
import { Redirect, Switch } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { ContestDetails } from '../pages/admin/admin_event_details/contests/contest_details/ContestDetails'
import { VerifyUserGuard } from './VerifyUserGuard'
const { NX_PUBLIC_ADMIN_LOGO_LINK } = process.env
// PUBLIC ROUTES:
const AuthMethodLogin = lazy(() =>
  import('../pages/public/auth_method_login/AuthMethodLogin').then((mod: any) => ({ default: mod.AuthMethodLogin })),
)
const AuthVerify = lazy(() => import('../pages/public/auth_verify/AuthVerify').then((mod: any) => ({ default: mod.AuthVerify })))
const AuthAppSetup = lazy(() => import('../pages/public/auth_app_setup/AuthAppSetup').then((mod: any) => ({ default: mod.AuthAppSetup })))
const VoterLogin = lazy(() => import('../pages/public/voter_login/VoterLogin').then((mod: any) => ({ default: mod.VoterLogin })))
const AdminLogin = lazy(() => import('../pages/public/admin_login/AdminLogin').then((mod: any) => ({ default: mod.AdminLogin })))

// VOTER ROUTES:
const VoterDashboard = lazy(() =>
  import('../pages/voter/voter_dashboard/VoterDashboard').then((mod: any) => ({ default: mod.VoterDashboard })),
)
const CandidateProfile = lazy(() =>
  import('../pages/voter/candidate_profile/CandidateProfile').then((mod: any) => ({ default: mod.CandidateProfile })),
)
const VoterMyCandidacy = lazy(() =>
  import('../pages/voter/voter_my_candidacy/VoterMyCandidacy').then((mod: any) => ({ default: mod.VoterMyCandidacy })),
)
const CandidacyDetails = lazy(() =>
  import('../pages/voter/candidacy_details/CandidacyDetails').then((mod: any) => ({ default: mod.CandidacyDetails })),
)
const ElectionDetails = lazy(() =>
  import('../pages/voter/election_details/ElectionDetails').then((mod: any) => ({ default: mod.ElectionDetails })),
)

// ADMINROUTES:
const AdminEvents = lazy(() => import('../pages/admin/admin_events/AdminEvents').then((mod: any) => ({ default: mod.AdminEvents })))
const AdminEventDetails = lazy(() =>
  import('../pages/admin/admin_event_details/AdminEventDetails').then((mod: any) => ({ default: mod.AdminEventDetails })),
)
const AdminNomineeDetails = lazy(() =>
  import('../pages/admin/admin_event_details/contests/contest_details/nominee_details/AdminNomineeDetails').then((mod: any) => ({
    default: mod.AdminNomineeDetails,
  })),
)
const AdminCreateEvent = lazy(() =>
  import('../pages/admin/admin_create_event/AdminCreateEvent').then((mod: any) => ({ default: mod.AdminCreateEvent })),
)

const NvRoute = ({ layout: Layout = null, component: Component = null, render = null, ...props }) => {
  return Layout ? (
    <Route render={() => <Layout>{render ? render() : <Component />}</Layout>} {...props} />
  ) : (
    <Route render={render} component={Component} {...props} />
  )
}

const VoterLayout = ({ children }) => {
  const { t } = useTranslation()
  const voterLinks = {
    elections: {
      path: '/voting/voter/dashboard',
      tabLabel: t('Nominations'),
      icon: Nominations,
      hasPermissionToView: true,
    },
    candidateProfile: {
      path: '/voting/voter/my-candidacy',
      tabLabel: t('pages.myCandidacy'),
      icon: Users,
      hasPermissionToView: true,
    },
  }

  return (
    <>
      <SideBar links={voterLinks} logo={<QueenslandLogoNavy />} collapsedLogo={<QueenslandLogoCollapsed />} />
      <div className="main-content-container">
        <TopBar
          links={voterLinks}
          logo={<QueenslandLogoNavy />}
          mobileLogo={<QueenslandLogoWhite />}
          className={'queensland'}
          disableSessionExtension
          hasLanguage={false}
          showCurrentTime={false}
        />
        {children}
        <BottomBar links={voterLinks} />
        <FooterBanner hasSidebar />
      </div>
    </>
  )
}

const AdminLayout = ({ children }) => {
  const { t } = useTranslation()

  const adminLinks = {
    events: {
      path: '/admin/dashboard',
      tabLabel: t('Nominations'),
      icon: Nominations,
      hasPermissionToView:
        KeycloakAdminService.isSuperAdmin() || KeycloakAdminService.isEventModuleAdmin() || KeycloakAdminService.isMonitoringAdmin(),
    },
    ems: {
      link: `${NX_PUBLIC_ADMIN_LOGO_LINK}/welcome`,
      tabLabel: t('Elections'),
      icon: EMSIcon,
      hasPermissionToView:
        KeycloakAdminService.isSuperAdmin() || KeycloakAdminService.isEventModuleAdmin() || KeycloakAdminService.isMonitoringAdmin(),
    },
  }

  return (
    <>
      <SideBar
        links={adminLinks}
        logo={<QueenslandLogoNavy />}
        collapsedLogo={<QueenslandLogoCollapsed />}
        onClickLogo={() => {
          window.removeEventListener('beforeunload', beforeUnload)
          window.location.href = `${NX_PUBLIC_ADMIN_LOGO_LINK}/module_pick`
        }}
      />
      <div className="main-content-container">
        <TopBar
          links={adminLinks}
          logo={<QueenslandLogoNavy />}
          mobileLogo={<QueenslandLogoWhite />}
          className={'queensland'}
          disableSessionExtension
          hasLanguage={false}
          showCurrentTime={false}
        />
        {children}
        <FooterBanner hasSidebar />
      </div>
    </>
  )
}

const PublicLayout = ({ children }) => {
  const [authMethod, setAuthMethod] = useState(null)

  return (
    <div style={{ flexDirection: 'column', width: '100%' }}>
      <VoterOnboardingContext.Provider value={{ authMethod, setAuthMethod }}>
        <LoginTopBar logo={<QueenslandLogoWhite />} hasLanguage={false} className={'queensland'} />
        <BackgroundBanner banner={'queensland'} />
        {children}
        <FooterBanner />
      </VoterOnboardingContext.Provider>
    </div>
  )
}

const RoutesWrapper = () => {
  const [loginFlow, setLoginFlow] = useState('Login')

  return (
    <SessionTimerProvider>
      <VoterUserProvider>
        <AdminUserProvider>
          <LoginFlowContext.Provider value={{ loginFlow, setLoginFlow }}>
            <Suspense fallback={<Loader />}>
              <Routes />
            </Suspense>
          </LoginFlowContext.Provider>
        </AdminUserProvider>
      </VoterUserProvider>
    </SessionTimerProvider>
  )
}
const Routes = () => {
  // NOTE from laura: sorry know this isn't super clean, just need these to be within the providers
  if (window.location.pathname.startsWith('/admin')) {
    useAdminKeycloakAuth()
  } else {
    useVoterKeyCloakAuth()
  }

  return (
    <Switch>
      {/* ADMIN ROUTES */}
      <NvRoute
        exact
        path="/admin/dashboard"
        render={() => <VerifyUserGuard component={AdminEvents} redirectURL={'/admin/login'} />}
        layout={AdminLayout}
      />
      <NvRoute
        exact
        path="/admin/events"
        render={() => <VerifyUserGuard component={AdminEvents} redirectURL={'/admin/login'} />}
        layout={AdminLayout}
      />
      <NvRoute
        exact
        path="/admin/create-event"
        render={() => <VerifyUserGuard component={AdminCreateEvent} redirectURL={'/admin/login'} />}
        layout={AdminLayout}
      />
      <NvRoute
        exact
        path="/admin/event-details/:eventId"
        render={() => <VerifyUserGuard component={AdminEventDetails} redirectURL={'/admin/login'} />}
        layout={AdminLayout}
      />
      <NvRoute
        exact
        path="/admin/candidates/:candidateId"
        render={() => <VerifyUserGuard component={AdminNomineeDetails} redirectURL={'/admin/login'} />}
        layout={AdminLayout}
      />
      <NvRoute
        exact
        path="/admin/event-details/:eventId/contests/:qid"
        render={() => <VerifyUserGuard component={ContestDetails} redirectURL={'/admin/login'} />}
        layout={AdminLayout}
      />

      {/* VOTER ROUTES */}
      <NvRoute
        exact
        path="/voting/voter/dashboard"
        render={() => <VerifyUserGuard component={VoterDashboard} redirectURL={'/'} />}
        layout={VoterLayout}
      />
      <NvRoute
        exact
        path="/voting/voter/my-candidacy"
        render={() => <VerifyUserGuard component={VoterMyCandidacy} redirectURL={'/'} />}
        layout={VoterLayout}
      />
      <NvRoute
        exact
        path="/voting/voter/candidacy-details/:candidacyId"
        render={() => <VerifyUserGuard component={CandidacyDetails} redirectURL={'/'} />}
        layout={VoterLayout}
      />
      <NvRoute
        exact
        path="/voting/voter/election-details/:eventId"
        render={() => <VerifyUserGuard component={ElectionDetails} redirectURL={'/'} />}
        layout={VoterLayout}
      />
      <NvRoute
        exact
        path="/voting/voter/candidate-profile"
        render={() => <VerifyUserGuard component={CandidateProfile} redirectURL={'/'} />}
        layout={VoterLayout}
      />

      {/* PUBLIC ROUTES */}
      <NvRoute exact path="/" component={VoterLogin} layout={PublicLayout} />
      <NvRoute exact path="/admin/login" component={AdminLogin} layout={PublicLayout} />

      <NvRoute exact path="/voting/voter-verify/select-verifier" component={AuthMethodLogin} layout={PublicLayout} />
      <NvRoute exact path="/voting/voter-verify/verify" component={AuthVerify} layout={PublicLayout} />

      <NvRoute exact path="/admin/auth/verify" component={AuthVerify} layout={PublicLayout} />
      <NvRoute exact path="/admin/auth/auth-app-setup" component={AuthAppSetup} layout={PublicLayout} />

      <NvRoute path="/voting/voter/" render={() => <Redirect to="/voting/voter/dashboard" />} />
      <NvRoute path="/admin/" render={() => <Redirect to="/admin/dashboard" />} />
      <Redirect to="/" />
    </Switch>
  )
}
export { RoutesWrapper as Routes }
