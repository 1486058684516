import { SVG } from '../SVG'

export const EMSIcon = ({ inactive = false, style = {}, ...props }) => {
  return (
    <SVG width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" style={{ ...style }} {...props}>
      <g id="To-Review" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g
          id="Clone-/-Dropdown-Copy"
          transform="translate(-670.000000, -279.000000)"
          fill={inactive ? 'var(--primary-color)' : 'var(--svg-darker-fill)'}
          fillRule="nonzero"
        >
          <g id="Group-2-Copy" transform="translate(668.000000, 277.000000)">
            <g id="action/candidate" transform="translate(2.002995, 2.000000)">
              <g id="Group" transform="translate(0.000000, 0.000000)">
                <polygon
                  id="Path"
                  points="10.5207622 2.54594573 10.0205836 3.40594573 13.4818195 5.39594573 13.9819981 4.53594573"
                ></polygon>
                <polygon
                  id="Path"
                  points="9.02022636 5.13594573 12.4814623 7.12594573 12.9816409 6.26594573 9.52040497 4.26594573"
                ></polygon>
                <polygon
                  id="Path"
                  points="8.01986915 6.85594573 11.4811051 8.84594573 11.9812837 7.98594573 8.52004776 5.99594573"
                ></polygon>
                <path
                  d="M19.7040414,10.6659457 L17.3632055,8.34594573 C17.1731376,8.15594573 16.9130448,8.05594573 16.6529519,8.05594573 L14.2520946,8.05594573 L16.462884,4.23594573 C16.602934,3.99594573 16.5229055,3.69594573 16.2828197,3.55594573 L10.220655,0.0659457336 C10.1106157,-0.00405426642 9.97056571,-0.0140542664 9.84051928,0.0159457336 C9.71047284,0.0559457336 9.60043355,0.135945734 9.54041211,0.245945734 L5.01879752,8.04594573 L3.34820097,8.04594573 C3.07810453,8.04594573 2.82801522,8.15594573 2.63794735,8.33594573 L0.297111478,10.6559457 C0.0470221747,10.8959457 -0.0530135465,11.2459457 0.0270150304,11.5859457 L1.75763301,19.1959457 C1.84766516,19.6659457 2.26781518,19.9959457 2.74798665,19.9959457 L17.2531662,19.9959457 C17.7333377,19.9959457 18.1534877,19.6559457 18.2435199,19.1959457 L19.9741378,11.5859457 C20.0541664,11.2459457 19.9441271,10.8959457 19.7040414,10.6559457 L19.7040414,10.6659457 Z M2.99807595,10.0459457 L3.99843316,9.04594573 L4.47860462,9.04594573 C4.44859391,9.24594573 4.52862248,9.45594573 4.71869035,9.55594573 L5.55899041,10.0359457 L2.99807595,10.0359457 L2.99807595,10.0459457 Z M10.7908586,10.0459457 L10.9809265,9.71594573 L7.51969055,7.72594573 L7.01951194,8.58594573 L9.56041926,10.0459457 L7.55970483,10.0459457 L5.82908686,9.04594573 L5.64902256,8.94594573 L6.16920831,8.05594573 L6.16920831,8.05594573 L10.15063,1.18594573 L15.3524875,4.17594573 L11.951273,10.0459457 L10.8008622,10.0459457 L10.7908586,10.0459457 Z M13.1016838,10.0459457 L13.681891,9.04594573 L16.0027197,9.04594573 L17.0030769,10.0459457 L13.1016838,10.0459457 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SVG>
  )
}
